export class User {
  public id?: number;
  public name?: string;
  public email?: string;
  public phone?: string;
  public time_zone?: string;
  public photo?: string;
  public created_at?: Date;
  public updated_at?: Date;
  public roles?: Array<any>;
  constructor() {
  }
}
