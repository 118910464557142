import { throwError as observableThrowError } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of as ObservableOf } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { User } from '../models/user';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppConfig } from '../app.config';

declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly USER_TOKEN = 'token';
  readonly USER_REFRESH_TOKEN = 'refresh_token';
  readonly USER_PERMISSIONS = 'user_permissions';

  private ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);
  public user: User = new User();

  constructor(
    public http: HttpClient,
    public router: Router,
    public configService: AppConfig,
    private zone: NgZone
  ) {}

  login(username: any, password: any, remember_me: boolean = false): Observable<any> {
    return this.http
      .post(this.configService.config.API_LOGIN + '/api/auth/login', {
        email: username,
        password: password,
        remember_me: remember_me,
        client_id: this.configService.config.CLIENT_ID,
        client_secret: this.configService.config.CLIENT_SECRET,
        grant_type: 'password',
        scope: '*',
      })
      .pipe(
        tap((p) => {}),
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    return observableThrowError(errMsg);
  }

  clearAllLocalStorage() {
    localStorage.removeItem(this.USER_TOKEN);
    localStorage.removeItem(this.USER_REFRESH_TOKEN);
    localStorage.removeItem(this.USER_PERMISSIONS);
    localStorage.removeItem('user');
    localStorage.removeItem('contributors');
    localStorage.removeItem('rut');
    localStorage.removeItem('id_branch');
    localStorage.removeItem('current_contributors');
    sessionStorage.removeItem('rut');
    sessionStorage.removeItem('id_branch');
    sessionStorage.removeItem('current_contributors');
  }

  logout() {
    this.clearAllLocalStorage();
    return this.router.navigate(['/iniciar-sesion']);
  }

  isAuthenticated(): boolean {
    const jwtHelper = new JwtHelperService();
    return this.getToken() ? !jwtHelper.isTokenExpired(this.getToken()) : false;
  }

  saveTokenIntegrations(request: object) {
    return this.http
      .post(this.configService.config.API_LOGIN + `/oauth/personal-access-tokens`, request)
      .pipe(
        tap((p) => {}),
        catchError(this.handleError)
      );
  }

  setUser(user: any) {
    user.time_zone = user.time_zone ? user.time_zone : 'America/Santiago';
    localStorage.setItem('user', JSON.stringify(user));
    return (this.user = user);
  }

  setToken(credentials: any) {
    localStorage.setItem(this.USER_REFRESH_TOKEN, credentials.refresh_token);
    return localStorage.setItem(this.USER_TOKEN, credentials.access_token);
  }

  storeRutContributors(rut: string): void {
    sessionStorage.setItem('rut', rut);
    localStorage.setItem('rut', rut);
  }

  storeIdBranch(id: any): void {
    localStorage.setItem('id_branch', id);
    sessionStorage.setItem('id_branch', id);
  }

  setContributors(contributors: any): void {
    localStorage.setItem('contributors', JSON.stringify(contributors));
  }

  setCurrentContributors(contributor: any): void {
    localStorage.setItem('current_contributors', JSON.stringify(contributor));
    sessionStorage.setItem('current_contributors', JSON.stringify(contributor));
  }

  setPermissions(permissions: string[] = []): void {
    localStorage.setItem('permissions', JSON.stringify(permissions));
  }

  setCertifiedDocuments(dtes: number[] = []) {
    localStorage.setItem('documents', JSON.stringify(dtes));
    sessionStorage.setItem('documents', JSON.stringify(dtes));
  }

  storeUserPermissions(permissions: any) {
    if (!localStorage.getItem('ip_public')) {
      this.http.get<{ ip: string }>('https://ipinfo.io?token=deda24efd7c3fa').subscribe((data) => {
        localStorage.setItem('ip_public', data.ip);
      });
    }

    this.determineLocalIp();

    return localStorage.setItem(this.USER_PERMISSIONS, JSON.stringify(permissions));
  }

  getDataUser() {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }

  getUser(rut: string): Observable<any> {
    return this.http
      .get<any>(this.configService.config.API_URL + `api/auth/contributor/${rut}/user`)
      .pipe(
        tap((p) => {}),
        catchError(() => {
          return ObservableOf(false);
        })
      );
  }

  getContributors() {
    return this.IsJsonString(localStorage.getItem('contributors'))
      ? JSON.parse(localStorage.getItem('contributors') || '[]')
      : [];
  }

  getRutContributors() {
    if (sessionStorage.getItem('rut')) {
      return sessionStorage.getItem('rut');
    } else if (localStorage.getItem('rut')) {
      return localStorage.getItem('rut');
    } else {
      return false;
    }
  }


  getCurrentContributor() {
    if (sessionStorage.getItem('current_contributors')) {
      return JSON.parse(sessionStorage.getItem('current_contributors') || '{}');
    } else if (localStorage.getItem('current_contributors')) {
      return JSON.parse(localStorage.getItem('current_contributors') || '{}');
    } else {
      return {};
    }
  }

  getIdBranch() {
    if (sessionStorage.getItem('id_branch')) {
      return sessionStorage.getItem('id_branch');
    } else if (localStorage.getItem('id_branch')) {
      return localStorage.getItem('id_branch');
    } else {
      return false;
    }
  }

  getToken() {
    return localStorage.getItem(this.USER_TOKEN) || '';
  }

  getPermissions() {
    const scopes = this.IsJsonString(localStorage.getItem('permissions'))
      ? JSON.parse(localStorage.getItem('permissions') || '[]')
      : [];
    return scopes;
  }

  getCertifiedDocuments() {
    if (sessionStorage.getItem('documents')) {
      return JSON.parse(sessionStorage.getItem('documents') || '[]');
    } else if (localStorage.getItem('documents')) {
      return JSON.parse(localStorage.getItem('documents') || '[]');
    } else {
      return false;
    }
  }

  determineLocalIp() {
    window.RTCPeerConnection =
      window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    const pc = new RTCPeerConnection({ iceServers: [] });
    pc.createDataChannel('');
    pc.createOffer().then(pc.setLocalDescription.bind(pc));

    pc.onicecandidate = (ice) => {
      this.zone.run(() => {
        if (!ice || !ice.candidate || !ice.candidate.candidate) {
          return;
        }
        try {
          let dataIp: Array<any> = this.ipRegex.exec(ice.candidate.candidate) || [];
          localStorage.setItem('ip', dataIp.length > 1 ? dataIp[1] : '');
        } catch (e) {
          localStorage.setItem('ip', '0.0.0.0');
        }
        pc.onicecandidate = () => {};
        pc.close();
      });
    };
  }

  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
